import Vue from 'vue'
import vuex from 'vuex'
import axios from "axios";

import passport from './passport'

Vue.use(vuex);

//实例state对象
const store = new vuex.Store({
    modules: {
        passport
    },
    //全局状态
    state: {
        staff: {
            tel: '',
            id: '',
            name: '',
            email: '',
            recName: '',
            recTel: '',
            recAddr: '',
            recGift:'',
            recCity:[],
            giftType: '',

        },
        score: '',
        itemNum: 1, //当前题号
        itemDetail: []
    },

    getters: {
        isSubmit: state => {
            // console.log('loadIsSubmit', state.itemDetail[state.itemNum-1].isSubmit || false);
            return state.itemDetail[state.itemNum - 1].isSubmit || false;
        },
        isCorrect: state => {
            let item = state.itemDetail[state.itemNum - 1];
            console.log('isCorrect', item.answer, item.userChoose);
            if (Array.isArray(item.answer)) {
                if (item.answer.length !== item.userChoose.length) {
                    return false;
                }
                let wrong = false;
                item.userChoose.every(val => {
                    if (item.answer.indexOf(val) === -1) {
                        wrong = true;
                        // console.log('111')
                        return false;
                    }
                    return true;
                })
                return !wrong;
            } else {
                return item.userChoose === item.answer;
            }
        },
        getResult: state => {
            let result = [];
            state.itemDetail.forEach(item => {
                result.push({
                    question: item.question,
                    answer: item.answer,
                    userChoose: item.userChoose
                })

            })

            return result;
        },
        getScore: state => {
            let score = 0;
            state.itemDetail.forEach(item => {
                if (Array.isArray(item.answer)) {
                    if (item.userChoose.length === item.answer.length) {
                        let wrong = false;
                        item.userChoose.every(val => {
                            if (item.answer.indexOf(val) === -1) {
                                wrong = true;
                                return false;
                            }
                            return true;
                        })
                        if (wrong !== true) {
                            score++;
                        }
                    }
                } else {
                    if (item.userChoose === item.answer) {
                        score++;
                    }
                }
            })

            return score;
        },
    },
    actions: {
        getChosenResult(ctx) {
            return !ctx.state.itemDetail[ctx.state.itemNum - 1].userChoose ?
                (ctx.state.itemDetail[ctx.state.itemNum - 1].type === 'checkbox' ? [] : '') : ctx.state.itemDetail[ctx.state.itemNum - 1].userChoose;
        },
        isCorrectChosen(ctx) {
            let item = ctx.state.itemDetail[ctx.state.itemNum - 1];
            console.log('checkCorrect', item.answer, item.userChoose);
            if (Array.isArray(item.answer)) {
                if (item.answer.length !== item.userChoose.length) {
                    return false;
                }
                let wrong = false;
                item.userChoose.every(val => {
                    if (item.answer.indexOf(val) === -1) {
                        wrong = true;
                        // console.log('111')
                        return false;
                    }
                    return true;
                })
                return !wrong;
            } else {
                return item.userChoose === item.answer;
            }
        },
        nextQuestion(ctx) {
            ctx.commit('addNum')
            // console.log(store.state.itemNum);
        },
        beforeQuestion(ctx) {
            ctx.commit('beforeNum')
            // console.log(store.state.itemNum);
        },
        getSubmitStats(ctx){
            return new Promise((resolve, reject) =>{
                axios.get('/status').then(rsp=>{
                    resolve(rsp.data);
                }).catch(e=>{
                    reject(e.response.data);
                })
            })
        },
        initData(ctx) {
            return new Promise((resolve, reject) => {
                axios.get('/questions').then(rsp => {
                    rsp.data.forEach(ele=>{
                        if(ele.type==='checkbox'){
                            ele.userChoose = [];
                        }else{
                            ele.userChoose = '';
                        }
                    })
                    ctx.commit('setQuestions', rsp.data);
                    ctx.commit('initData');

                    resolve();
                }).catch(e => {
                    reject(e.response.data);
                })
            })
        },
        setAnswer(ctx, payload) {
            // console.log(payload);
            ctx.commit('rememberAnswer', payload);
        },
        submitAnswer(ctx) {
            ctx.commit('saveSubmitAnswer');
        }
    },

    //定义处理函数
    mutations: {
        addNum(state, payload) {
            if (this.state.itemNum < this.state.itemDetail.length) {
                this.state.itemNum += 1;
            }
        },
        beforeNum(state, payload) {
            if (this.state.itemNum >= 0) {
                this.state.itemNum -= 1;
            }
        },
        rememberAnswer(state, payload) {
            // console.log(state.itemNum-1, payload);
            state.itemDetail[state.itemNum - 1].userChoose = payload;
            console.log('state:rememberAnswer', state.itemDetail[state.itemNum - 1]);

        },
        saveSubmitAnswer(state) {
            state.itemDetail[state.itemNum - 1].isSubmit = true;
        },
        initData(state) {
            state.itemNum = 1;
            state.userAnswer = [];
        },
        setQuestions(state, payload) {
            state.itemDetail = payload;
        }
    },


});

export default store
